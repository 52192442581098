import React from 'react';

import './Newsletter.scss';

function Newsletter() {
  return (
    <div className="newsletter">
      <div className="wrapper">
        <div id="mc_embed_signup">
          <form action="//soerjadi.us12.list-manage.com/subscribe/post?u=dad371aef43b2eb133c73cab8&amp;id=de6bffaf47"
                method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate"
                target="_blank" noValidate>
            <div id="mc_embed_signup_scroll">
              <h2>Stay tuned for all the latest updates!</h2>
              <p>Do you want to be the first to know what's up next?</p>
              <div className="newsletter__form">
                <input type="email" placeholder="you@awesome.com" name="EMAIL" className="required email"
                       id="mce-EMAIL" />
                  <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" />
              </div>
              <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
                <input type="text" name="b_dad371aef43b2eb133c73cab8_de6bffaf47" tabIndex="-1" value="" />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="overlay" />
    </div>
  )
}

export default Newsletter;