import React from 'react';
import StoreLayout from '../components/StoreLayout/StoreLayout';
import SEO from '../components/SEO/SEO';
import Newsletter from '../components/Newsletter/Newsletter';
import { graphql } from 'gatsby';
import Moment from 'react-moment';

import '../scss/Calendar.scss';

const Events = ({ pageContext, data }) => {
    const { allEventsJson: events } = data;
    const { title, poster } = pageContext;

    return (
        <StoreLayout page={title.toLowerCase()} poster={poster}>
            <SEO
              title={title}
              description="Everything about Wibi Soerjadi, his history, life path and his amazing sheet music is available right here."
              keywords={[`wibi`, `soerjadi`, `wibi soerjadi`, `about`, `masterclass`, `summercourse`, `academy`, `sheet`, `music`, `sheet music`, `shop`]}
            />
            <main className="main">
                <div className="wrapper">
                    <h1 className="main-title">{title}</h1>

                    <div className="concerts">
                        { events.edges.map((eventNode) => {
                            const { node: event } = eventNode;
                            const date = event.date;

                            return (
                                <div className="concert">
                                    <div className="concert__meta">
                                        <h2>
                                            <a href={ event.external_url } target="_blank" rel="noreferrer">{ event.title }</a>
                                        </h2>
                                        <p>
                                          { event.location } <span>•</span><Moment format="dddd DD MMMM YYYY">{ date }</Moment>
                                        </p>
                                        </div>
                                        <div className="concert__button">
                                        <a href={ event.external_url } target="_blank" rel="noreferrer" className="btn btn--primary">
                                            <span className="available">View tickets</span>
                                            <span className="sold-out">Coming soon</span>
                                        </a>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </main>
            <Newsletter />
        </StoreLayout>
    )
};

export const query = graphql`
    query EventsQuery {
        allEventsJson(sort: {fields: [date], order: ASC}) {
            edges {
              node {
                external_url
                date
                title
                location
              }
            }
        }
    }
`

export default Events;